.bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/webBG2.png");

  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #999;
}

h {
  color: "#fff";
  font-family: "Roboto";
}

h2 {
  color: "#fff";
  font-family: "Roboto";
  font-size: "5vmin";
}

p {
  color: "#fff";
  font-family: "Roboto";
  font-size: "5vmin";
}

.mobileShow {
  display: none;
}

@media only screen and (max-width: 479px) {
  .mobileShow {
    display: block;
  }
}

.desktopShow {
  display: none;
}

@media only screen and (min-width: 480px) {
  .desktopShow {
    display: block;
  }
}
