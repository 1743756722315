@import "@animxyz/core";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  background-image: url("../img/background.jpg");
  width: 100%;
  background-size: cover;
  background-position: center;
  text-align: center;
}

.jumbotron h1 {
  font-size: 32px;
  color: black;
  font-weight: bold;
}

.button p {
  color: white;
  font-weight: bold;
}

.text-link {
  text-decoration: underline;
  color: black;
  font-weight: bold;
}

td,
th {
  text-align: left;
  padding: 8px;
}
