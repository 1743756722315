@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Comfortaa";
  src: url("../fonts/Comfortaa[wght].ttf");
}

@font-face {
  font-family: "Staatliches-Regular";
  src: url("../fonts/Staatliches-Regular.ttf");
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: "Kanit";
  src: url("../fonts/Kanit-Regular.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.ttf");
}

body,
html {
  overflow-x: hidden;
  margin: 0;
  background-color: #f0f8f8;
  font-family: "Roboto";
}

a + a {
  margin-left: 30px;
}

@media screen and (max-width: 420px) {
  a + a {
    margin: 0 !important;
  }
}

p {
  color: "#fff";
}

img {
  max-width: 100%;
}

.btn {
  border-width: 1px;
  border-color: aliceblue;
  border-radius: 20;
}

.bm-burger-button {
  position: relative;
  width: 24px;
  height: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0px;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #1e2223;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #1e2223;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.reset-tw,
.reset-tw * {
    all: revert !important;
    
}

.reset-tw {
  padding: 5vw !important;
  padding-top: 2vw !important;
}

.no-bullet {
  list-style-type: none !important;
}

.textshadow {
  text-shadow: 3px 3px 6px #000000
}